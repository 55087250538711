import Vue from 'vue';
import Buefy from 'buefy';
import App from './App.vue';
import VueRouter from 'vue-router';
import router from './router/router';
import VueTheMask from 'vue-the-mask';
import Vuelidate from 'vuelidate';
import store from './store/store';
import VueTour from 'vue-tour';
import Gtm from './store/plugins/gtm.js';

// Injecao do GTM;
Gtm();

// Layout padrao do tour 
// require('vue-tour/dist/vue-tour.css');

Vue.use(VueTheMask);
Vue.use(VueRouter);
Vue.use(Buefy);
Vue.use(Vuelidate);
Vue.use(VueTour);

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
