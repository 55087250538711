import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';
import { getDynamicIeBySubDomain } from "@commons/helpers";
import router from '../../router/router';

const loadConfig = (ie) => {
    return require(`../../ies/${ie}.config.json`);
}

export default function () {
    try {
        const iesList = require('../../ies/ies.list.json');
    
        let config = null;
        let selectedIe = getDynamicIeBySubDomain(window.location.hostname, iesList);
    
        config = loadConfig(selectedIe);
    
        Vue.use(VueGtm, {
            id: 'GTM-5VSJBRP',
            loadScript: true,
            enabled: (config.sigla == 'ebr') ? true : false,
            vueRouter: router
        });
    } catch(e) {
      console.error(e)
    }
}

